import BaseService from '~/services/BaseService';
import { type ProductCategory } from '~/services/product/category/type';

import { type ApiResponse, type ApiResponseInner, type PaginationOptions } from '~/types/global';

import { type BoardContentsData } from '../community';
import { type UserSurveyDTO } from '../payment';
import {
  type ExpectedRefundAmount,
  type ExpectedRefundAmountOptions,
  type FollowUserDatas,
  type GetContentsByWriterOptions,
  type GetFollowUserOptions,
  type GetMyPageCouponOptions,
  type GetMyPageReviewOption,
  type GetStudyReportClassOptions,
  type HonorsApplyProduct,
  type HonorsAvailableStatus,
  type LectureFile,
  type MyHonorsData,
  type MyLevel,
  type MyPageBadge,
  type MyPageCouponsData,
  type MyPageFavoriteClass,
  type MyPageFavoriteClassOptions,
  type MyPageInfo,
  type MyPageInviteInfo,
  type MyPageInvitedUser,
  type MyPageMainInfo,
  type MyPageMainUser,
  type MyPagePaymentImportResult,
  type MyPagePaymentSearchOptions,
  type MyPagePayments,
  type MyPagePointInfo,
  type MyPagePointLogs,
  type MyPageProfile,
  type MyPageRecentClass,
  type MyPageRecentClassOptions,
  type MyPageRefundRequestCancelOptions,
  type MyPageRefundRequestOptions,
  type MyPageReportInfoOptions,
  type MyPageReviewsData,
  type MyPageStudyRoomClass,
  type MyPageStudyRoomClassOptions,
  type MyPageStudyRoomClasses,
  type MyPageStudyRoomLecture,
  type MyPageStudyRoomLectureOptions,
  type MyPageSurveyResponse,
  type PaymentRefund,
  type PostMyPageReviewOptions,
  type PutMyPageInfoOptions,
  type PutMyPageReviewOptions,
  type PutMyProfileOptions,
  type RecentLecture,
  type RefundReason,
  type StudyBreakOptions,
  type StudyCertificatesData,
  type StudyReport,
  type StudyReportEdit,
  type StudyReportInfo,
  type StudyReportsData,
  type StudyTextbookOptions,
} from './type';

class MyPageService extends BaseService {
  constructor() {
    super('');
  }

  getMyPageMainUserInfo() {
    return this.get<{ items: MyPageMainUser }>(`/user/mypage/main/info`);
  }

  getMyLevel() {
    return this.get<{ items: MyLevel }>(`/user/profile/me/level`);
  }

  getMyPageProfileInfo(userId: number) {
    return this.getV2<ApiResponse<ApiResponseInner<MyPageProfile>>>(`/mypage/profile/info/${userId}`);
  }

  getMyBoardContents(params: GetContentsByWriterOptions) {
    return this.getV2<ApiResponse<BoardContentsData>>('/mypage/profile/bbs/contents/list', { params });
  }

  getMyBoardLikeContents(params: GetContentsByWriterOptions) {
    return this.get<BoardContentsData>('/mypage/profile/bbs/contents/like/list', { params });
  }

  getMyBoardScrapContents(params: GetContentsByWriterOptions) {
    return this.getV2<ApiResponse<BoardContentsData>>('/user/community/board/contents/scraps', { params });
  }

  getMyBoardCommentContents(params: GetContentsByWriterOptions) {
    return this.get<BoardContentsData>('/mypage/profile/bbs/comment/list', { params });
  }

  getMypageFollowers({ userId, ...params }: GetFollowUserOptions) {
    return this.get<FollowUserDatas>(`/mypage/follows/follower/list/${userId}`, { params });
  }

  getMypageFollowings({ userId, ...params }: GetFollowUserOptions) {
    return this.get<FollowUserDatas>(`/mypage/follows/following/list/${userId}`, { params });
  }

  getMyPageCoupons({ userId, ...params }: GetMyPageCouponOptions) {
    return this.get<MyPageCouponsData>(`/user/mypage/coupon/list/${userId}`, { params });
  }

  postMyPageCouponCode(couponCode: string) {
    return this.post('/user/mypage/coupon/reg', { couponCode });
  }

  getMyPageInviteInfo() {
    return this.get<{ items: MyPageInviteInfo }>(`/user/mypage/invite/info`);
  }

  postMyPageInviteCode(inviteCode: string) {
    return this.post('/user/mypage/invite/recommend/insert', { inviteCode });
  }

  getMyPageBadges() {
    return this.get<{ items: MyPageBadge[] }>(`/users/badges`);
  }

  getUserBadges(userId: number) {
    return this.get<{ items: MyPageBadge[] }>(`/users/${userId}/badges`);
  }

  getMyPageInfo() {
    return this.get<{ items: MyPageInfo }>('/user/mypage/info/manage');
  }

  putMyPageInfo(params: PutMyPageInfoOptions) {
    return this.put('/user/mypage/info/manage', params);
  }

  postMyPageUserLeave(userId: number) {
    return this.post('/user/mypage/info/leave', { userId });
  }

  getMyPageInvitedUsers() {
    return this.get<{ items: MyPageInvitedUser[] }>(`/user/mypage/invite/recommend/list`);
  }

  getMyPageMainInfo() {
    return this.get<{ items: MyPageMainInfo }>(`/user/mypage/main/study/info`);
  }

  getMyPageRecentClasgetMyPageRecentClasss(params: MyPageRecentClassOptions) {
    return this.get<MyPageRecentClass>(`/user/mypage/main/recent/list`, { params });
  }

  getMyPageFavoriteClass(params: MyPageFavoriteClassOptions) {
    return this.get<MyPageFavoriteClass>(`/user/mypage/main/favorite/list`, { params });
  }

  getMyPageStudyRoomClasses(params: MyPageStudyRoomClassOptions) {
    return this.getV2<ApiResponse<MyPageStudyRoomClasses>>(`/user/mypage/studyroom/study/class/list`, { params });
  }

  getMyPageSurvey(params: PaginationOptions) {
    return this.get<MyPageSurveyResponse>(`/user/survey`, { params });
  }

  getMyPageSurveyItem(surveySeq: number, studyId: number) {
    return this.get<ApiResponseInner<UserSurveyDTO>>(`/user/survey/${surveySeq}?studyId=${studyId}`);
  }

  getMyPageStudyRoomLectureClassInfo(params: MyPageStudyRoomLectureOptions) {
    return this.getV2<ApiResponse<ApiResponseInner<MyPageStudyRoomClass>>>(
      `/user/mypage/studyroom/study/class/${params.studySeq}`,
    );
  }

  getMyPageStudyRoomLectures(params: MyPageStudyRoomLectureOptions) {
    return this.get<{ items: MyPageStudyRoomLecture[] }>(
      `/user/mypage/studyroom/study/class/lecture/list/${params.studySeq}/${params.displaySeq}`,
    );
  }

  getMyPageWriteReviews(params: GetMyPageReviewOption) {
    return this.get<MyPageReviewsData>('/user/mypage/study/review/display/list/write', { params });
  }

  getMyPageModifyReviews(params: GetMyPageReviewOption) {
    return this.get<MyPageReviewsData>('/user/mypage/study/review/display/list/modify', { params });
  }

  postMyPageReview(params: PostMyPageReviewOptions) {
    return this.post('/user/mypage/study/review/create', params);
  }

  putMyPageReview(params: PutMyPageReviewOptions) {
    return this.put('/user/mypage/study/review/display/info', params);
  }

  deleteMyPageReview(reviewSeq: number) {
    return this.delete(`/user/mypage/study/review/display/info/delete/${reviewSeq}`);
  }

  getStudyReportClassList(params: GetStudyReportClassOptions) {
    return this.get<StudyReportsData>('/user/mypage/studyroom/study/report/class/list', { params });
  }

  getStudyReportList(params: { studySeq: number; displaySeq: number }) {
    return this.get<{ items: StudyReportInfo[] }>('/user/mypage/studyroom/study/report/list', { params });
  }

  getCertificateList(params: PaginationOptions) {
    return this.get<StudyCertificatesData>('/mypage/certificate/display/list/', { params });
  }

  getCertificateInfo(studySeq: number, displaySeq: number) {
    return this.get<{
      items: {
        displayName: string;
        paymentPrice: string;
        printDate: string;
        studyCompleteDate: null | string;
        studyEndDate: string;
        studyStartDate: string;
        userName: string;
      };
    }>(`/mypage/certificate/info/${studySeq}/${displaySeq}`);
  }

  getMyPagePointInfo() {
    return this.get<{ items: MyPagePointInfo }>(`/user/mypage/point/balance`);
  }

  getMyPagePointLogs(params: PaginationOptions) {
    return this.get<MyPagePointLogs>(`/user/mypage/point/log/list`, { params });
  }

  getMyPageStudyRoomReportInfo(params: MyPageReportInfoOptions) {
    return this.get<{ items: StudyReport }>(`/user/mypage/studyroom/study/report/info`, { params });
  }

  getMyPageStudyRoomReportDetail(boardContentsSeq: number) {
    return this.get<{ items: StudyReportEdit }>(`/user/mypage/studyroom/study/report/${boardContentsSeq}`);
  }

  insertStudyReport(params: StudyReportEdit) {
    return this.post<{ items: StudyReportEdit }>('/user/mypage/studyroom/study/report/create', params);
  }

  updateStudyReport(params: StudyReportEdit) {
    return this.put('/user/mypage/studyroom/study/report/update', params);
  }

  applyStudyBreak(params: StudyBreakOptions) {
    return this.post('/user/mypage/studyroom/study/break/apply', params);
  }

  applyStudyBreakAlarm(params: StudyBreakOptions) {
    return this.post('/user/mypage/studyroom/study/break/apply/kakao/send', params);
  }

  cancelStudyBreak(params: StudyBreakOptions) {
    return this.post('/user/mypage/studyroom/study/break/cancel', params);
  }

  getMyPagePayments(params: MyPagePaymentSearchOptions) {
    return this.get<MyPagePayments>(`/user/mypage/payment/list`, { params });
  }

  getMyPagePaymentReceiptUrl(uid: string) {
    return this.get<{ items: MyPagePaymentImportResult }>(`/user/mypage/payment/detail/${uid}`);
  }

  checkUnderProductCategory(cateSeq: number, productSeq: number) {
    return $api.get<ApiResponse<{ items: ProductCategory[] }>>(
      `/v1/admin/product/category/under/${cateSeq}/${productSeq}`,
    );
  }

  getPaymentRefundReasons() {
    return $api.get<ApiResponse<{ items: RefundReason[] }>>(`/v1/user/refund/reason/list`);
  }

  requestPaymentRefund(params: MyPageRefundRequestOptions) {
    return this.post('/user/mypage/payment/refund/request', params);
  }

  getPaymentRefundLogs(paymentSeq: number) {
    return this.get<{ items: PaymentRefund[] }>(`/user/mypage/payment/refund/list/${paymentSeq}`);
  }

  cancelPaymentRefundRequest(params: MyPageRefundRequestCancelOptions) {
    return this.put('/user/mypage/payment/refund/request/cancel', params);
  }

  getExpectedRefundAmount(params: ExpectedRefundAmountOptions) {
    return this.get<{ items: ExpectedRefundAmount }>('/user/refund/expect/amount', { params });
  }

  getHonorsRequestStatus() {
    return this.get<{
      result: HonorsAvailableStatus;
    }>('/user/honors/available/check');
  }

  getHonorsRunningStatus() {
    return this.get<{
      result: HonorsAvailableStatus;
    }>('/user/honors/is-running');
  }

  requestHonors(params: { paymentIds: number[] }) {
    return this.post('/user/honors', params);
  }

  getHonorsAvailableProducts() {
    return this.get<{ items: HonorsApplyProduct[] }>('/user/honors/available/products');
  }

  getMyHonorsData() {
    return this.get<{ items: MyHonorsData[] }>('/user/honors');
  }

  getHonorsProducts(params?: PaginationOptions) {
    return this.get<{ items: HonorsApplyProduct[] }>('/user/honors/products', { params });
  }

  putMyPageProfileInfo(params: PutMyProfileOptions) {
    return this.put('/mypage/profile/info', params);
  }

  // 최근 들은 강의 내역 -> form/edit 에서 사용
  getRecentLectures(params: { days: number }) {
    return this.getV2<ApiResponse<ApiResponseInner<RecentLecture[]>>>('/users/lectures', { params });
  }

  getStudyTextbookDownloadUrl(params: { chapterFileSeq: LectureFile['chapterFileSeq'] }) {
    return this.getV2<ApiResponse<ApiResponseInner<{ fileUrl: string }>>>(
      `/user/mypage/studyroom/download/${params.chapterFileSeq}`,
    );
  }

  downStudyTextbook(params: StudyTextbookOptions) {
    return this.post('/user/mypage/studyroom/study/textbook/download', params);
  }
}

export const myPageService = new MyPageService();
