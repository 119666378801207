import * as _ from 'lodash-es';

import { type DisplayProduct } from '~/services/main';

import { type Pagination, type PaginationOptions } from '~/types/global';

import {
  type FollowUser,
  type GetContentsByWriterOptions,
  type GetFollowUserOptions,
  type GetMyPageCouponOptions,
  type GetMyPageReviewOption,
  type GetStudyReportClassOptions,
  type GetterFollowUser,
  type GetterMyPageCoupon,
  type GetterMyPageInfo,
  type GetterMyPageInviteInfo,
  type GetterMyPageMainUser,
  type GetterMyPageProfile,
  type GetterMyPageReview,
  type MyPageBadge,
  type MyPageCoupon,
  type MyPageFavoriteClassOptions,
  type MyPageInfo,
  type MyPageInviteInfo,
  type MyPageInvitedUser,
  type MyPageMainInfo,
  type MyPageMainUser,
  type MyPagePayment,
  type MyPagePaymentSearchOptions,
  type MyPagePointInfo,
  type MyPagePointLog,
  type MyPageProfile,
  type MyPageRecentClassOptions,
  type MyPageRefundRequestCancelOptions,
  type MyPageRefundRequestOptions,
  type MyPageReportInfoOptions,
  type MyPageReview,
  type MyPageStudyBreakDialogOptions,
  type MyPageStudyRoomClass,
  type MyPageStudyRoomClassOptions,
  type MyPageStudyRoomLecture,
  type MyPageStudyRoomLectureOptions,
  type MyPageStudyTextbookDialogOptions,
  type MyPageSurvey,
  type PaymentRefund,
  type RefundLogDialogOptions,
  type RefundReason,
  type RefundRequestDialogOptions,
  type StudyBreakOptions,
  type StudyCertificate,
  type StudyReport,
  type StudyReportEdit,
  type StudyTextbookOptions,
  myPageService,
} from '.';
import Utility from '../Utility';
import { type BoardContent, type GetterBoardContent } from '../community';
import { useUserAuthStore } from '../userAuth';

interface State {
  mainInfo: MyPageMainUser | null;
  profile: MyPageProfile | null;
  myContents: BoardContent[];
  myLikeContents: BoardContent[];
  myCommentContents: BoardContent[];
  myCoupons: MyPageCoupon[];
  myInvite: MyPageInviteInfo | null;
  followers: FollowUser[];
  followings: FollowUser[];
  myBadges: MyPageBadge[];
  myInfo: MyPageInfo | null;
  invitedUsers: MyPageInvitedUser[];
  myPageMainInfo: MyPageMainInfo | null;
  myPageRecentClass: {
    items: DisplayProduct[];
    paging?: Pagination;
  };
  myPageFavoriteClass: {
    items: DisplayProduct[];
    paging?: Pagination;
  };
  myPageStudyRoomClasses: {
    items: MyPageStudyRoomClass[];
    paging?: Pagination;
  };
  myPageSurveys: {
    items: MyPageSurvey[];
    paging?: Pagination;
  };
  myPageStudyRoomLectureClassInfo: MyPageStudyRoomClass | null;
  myPageStudyRoomLectures: MyPageStudyRoomLecture[];
  myPageStudyBreakDialogOptions: MyPageStudyBreakDialogOptions;
  myPageStudyTextbookDialogOptions: MyPageStudyTextbookDialogOptions;
  myPageWriteReviews: {
    items: MyPageReview[];
    paging?: Pagination;
  };
  myPageModifyReviews: {
    items: MyPageReview[];
    paging?: Pagination;
  };
  myStudyReports: {
    items: StudyReport[];
    paging?: Pagination;
  };
  myStudyCertificates: {
    items: StudyCertificate[];
    paging?: Pagination;
  };
  myPagePointInfo: MyPagePointInfo | null;
  myPagePointLogs: {
    items: MyPagePointLog[];
    paging?: Pagination;
  };
  selectedStudyReport: StudyReport | null;
  myPagePayments: {
    items: MyPagePayment[];
    paging?: Pagination;
  };
  refundRequestDialogOptions: RefundRequestDialogOptions;
  refundReasons: RefundReason[];
  refundLogDialogOptions: RefundLogDialogOptions;
  paymentRefundLogs: PaymentRefund[];
}

export const useMyPageStore = defineStore('my-page', {
  state: (): State => ({
    mainInfo: null,
    profile: null,
    myCommentContents: [],
    myContents: [],
    myLikeContents: [],
    myCoupons: [],
    myInvite: null,
    myBadges: [],
    followers: [],
    followings: [],
    invitedUsers: [],
    myInfo: null,
    myPageMainInfo: null,
    myPageRecentClass: {
      items: [],
    },
    myPageFavoriteClass: {
      items: [],
    },
    myPageStudyRoomClasses: {
      items: [],
    },
    myPageSurveys: {
      items: [],
    },
    myPageStudyRoomLectureClassInfo: null,
    myPageStudyRoomLectures: [],
    myPageStudyBreakDialogOptions: {
      visible: false,
      item: null,
    },
    myPageStudyTextbookDialogOptions: {
      visible: false,
      item: null,
    },
    myPageWriteReviews: { items: [] },
    myPageModifyReviews: { items: [] },
    myStudyCertificates: { items: [] },
    myStudyReports: { items: [] },
    myPagePointInfo: null,
    myPagePointLogs: {
      items: [],
    },
    selectedStudyReport: null,
    myPagePayments: {
      items: [],
    },
    refundRequestDialogOptions: {
      visible: false,
    },
    refundReasons: [],
    refundLogDialogOptions: {
      visible: false,
    },
    paymentRefundLogs: [],
  }),
  actions: {
    async fetchMainInfo() {
      const authUser = useUserAuthStore();
      if (!authUser.user) {
        throw new Error('로그인 권한이 필요합니다.');
      }
      const { data } = await myPageService.getMyPageMainUserInfo();
      this.mainInfo = data.data.items;
      return data;
    },
    async fetchMainProfile(userId: number) {
      const { data } = await myPageService.getMyPageProfileInfo(userId);
      this.profile = data?.items;
      return data;
    },
    async fetchMyContents(params: GetContentsByWriterOptions, append = false) {
      if (!append) {
        this.myContents = [];
      }
      let isPending = true;
      const { data } = await myPageService.getMyBoardContents(params);
      this.myContents = append ? this.myContents.concat(data.items) : data?.items || [];
      isPending = false;
      return { isPending, ...data };
    },
    async fetchMyLikeContents(params: GetContentsByWriterOptions, append = false) {
      const { data } = await myPageService.getMyBoardLikeContents(params);
      this.myLikeContents = append ? this.myLikeContents.concat(data.data.items) : data.data.items;
      return data;
    },
    async fetchMyCommentContents(params: GetContentsByWriterOptions, append = false) {
      const { data } = await myPageService.getMyBoardCommentContents(params);
      this.myCommentContents = append ? this.myCommentContents.concat(data.data.items) : data.data.items;
      return data;
    },
    async fetchFollowers(params: GetFollowUserOptions, append = false) {
      const { data } = await myPageService.getMypageFollowers(params);
      this.followers = append ? this.followers.concat(data.data.items) : data.data.items;
      return data;
    },
    async fetchFollowings(params: GetFollowUserOptions, append = false) {
      const { data } = await myPageService.getMypageFollowings(params);
      this.followings = append ? this.followings.concat(data.data.items) : data.data.items;
      return data;
    },
    async fetchMyCoupons(params: GetMyPageCouponOptions, append = false) {
      if (!append) {
        this.myCoupons = [];
      }
      const { data } = await myPageService.getMyPageCoupons(params);
      this.myCoupons = append ? this.myCoupons.concat(data.data.items) : data.data.items;
      return data;
    },
    async fetchMyInvite() {
      const { data } = await myPageService.getMyPageInviteInfo();
      this.myInvite = data.data.items;
      return data;
    },
    async fetchCurrentBadges(userId: number) {
      const myId = useUserAuthStore().user?.userId;

      if (String(myId) === String(userId)) {
        return await this.fetchMyBadges();
      }
      return await this.fetchUserBadges(userId);
    },
    // 내 뱃지 정보를 가져옵니다.
    async fetchMyBadges() {
      const { data } = await myPageService.getMyPageBadges();
      this.myBadges = data.data.items;
      return data;
    },
    // 내가 아닌 사람의 뱃지 정보를 가져옵니다.
    async fetchUserBadges(userId: number) {
      const { data } = await myPageService.getUserBadges(userId);
      this.myBadges = data.data.items;
      return data;
    },
    async fetcyMyPageInfo(userId: number) {
      const { data } = await myPageService.getMyPageInfo(userId);
      this.myInfo = data.data.items;
      return data;
    },
    async fetchMyPageInvitedUsers() {
      const { data } = await myPageService.getMyPageInvitedUsers();
      this.invitedUsers = data.data.items;
      return data;
    },
    async fetchMyPageMainInfo() {
      const { data } = await myPageService.getMyPageMainInfo();
      this.myPageMainInfo = data.data.items;
      return data;
    },
    async fetchMyPageRecentClass(params: MyPageRecentClassOptions, append = false) {
      const { data } = await myPageService.getMyPageRecentClass(params);
      if (append) {
        this.myPageRecentClass.items = this.myPageRecentClass.items.concat(data.data.items);
        this.myPageRecentClass.paging = data.data.paging;
      } else {
        this.myPageRecentClass = data.data;
      }
      return data;
    },
    async fetchMyPageFavoriteClass(params: MyPageFavoriteClassOptions, append = false) {
      const { data } = await myPageService.getMyPageFavoriteClass(params);
      if (append) {
        this.myPageFavoriteClass.items = this.myPageFavoriteClass.items.concat(data.data.items);
        this.myPageFavoriteClass.paging = data.data.paging;
      } else {
        this.myPageFavoriteClass = data.data;
      }
      return data;
    },
    async fetchMyPageStudyRoomClasses(params: MyPageStudyRoomClassOptions, append = false) {
      const { data } = await myPageService.getMyPageStudyRoomClasses(params);
      if (append) {
        this.myPageStudyRoomClasses.items = this.myPageStudyRoomClasses.items.concat(data.items);
        this.myPageStudyRoomClasses.paging = data.paging;
      } else {
        this.myPageStudyRoomClasses = data;
      }
      return data;
    },
    async fetchMyPageSurvey(params: PaginationOptions, append = false) {
      const { data } = await myPageService.getMyPageSurvey(params);
      if (append) {
        this.myPageSurveys.items = this.myPageSurveys.items.concat(data.data.items);
        this.myPageSurveys.paging = data.data.paging;
      } else {
        this.myPageSurveys = data.data;
      }
      return data;
    },
    async fetchMyPageStudyRoomLectureClassInfo(params: MyPageStudyRoomLectureOptions) {
      const { data } = await myPageService.getMyPageStudyRoomLectureClassInfo(params);
      this.myPageStudyRoomLectureClassInfo = data.items;
      return data;
    },
    async fetchMyPageStudyRoomLectures(params: MyPageStudyRoomLectureOptions) {
      const { data } = await myPageService.getMyPageStudyRoomLectures(params);
      this.myPageStudyRoomLectures = data.data.items;
      return data;
    },

    async fetchMyPageWriteReviews(params: GetMyPageReviewOption, append = false) {
      const { data } = await myPageService.getMyPageWriteReviews(params);
      if (append) {
        this.myPageWriteReviews.items = this.myPageWriteReviews.items.concat(data.data.items);
        this.myPageWriteReviews.paging = data.data.paging;
      } else {
        this.myPageWriteReviews = data.data;
      }
      return data;
    },
    async fetchMyPageModifyReviews(params: GetMyPageReviewOption, append = false) {
      const { data } = await myPageService.getMyPageModifyReviews(params);
      if (append) {
        this.myPageModifyReviews.items = this.myPageModifyReviews.items.concat(data.data.items);
        this.myPageModifyReviews.paging = data.data.paging;
      } else {
        this.myPageModifyReviews = data.data;
      }
      return data;
    },
    async fetchMyStudyReports(params: GetStudyReportClassOptions, append = false) {
      const { data } = await myPageService.getStudyReportClassList(params);
      if (append) {
        this.myStudyReports.items = this.myStudyReports.items.concat(data.data.items);
        this.myStudyReports.paging = data.data.paging;
      } else {
        this.myStudyReports = data.data;
      }
      return data;
    },
    async fetchMyStudyCertificates(params: PaginationOptions, append = false) {
      const { data } = await myPageService.getCertificateList(params);
      if (append) {
        this.myStudyCertificates.items = this.myStudyCertificates.items.concat(data.data.items);
        this.myStudyCertificates.paging = data.data.paging;
      } else {
        this.myStudyCertificates = data.data;
      }
      return data;
    },
    async fetchMyPagePointInfo() {
      const { data } = await myPageService.getMyPagePointInfo();
      this.myPagePointInfo = data.data.items;
      return data;
    },
    async fetchMyPagePointLogs(params: PaginationOptions, append = false) {
      const { data } = await myPageService.getMyPagePointLogs(params);
      if (append) {
        this.myPagePointLogs.items = this.myPagePointLogs.items.concat(data.data.items);
        this.myPagePointLogs.paging = data.data.paging;
      } else {
        this.myPagePointLogs = data.data;
      }
      return data;
    },
    async addStudyReport(params: StudyReportEdit) {
      const { data } = await myPageService.insertStudyReport(params);
      return data;
    },
    async modStudyReport(params: StudyReportEdit) {
      const { data } = await myPageService.updateStudyReport(params);
      return data;
    },
    async fetchMyPageStudyRoomReportInfo(params: MyPageReportInfoOptions) {
      const { data } = await myPageService.getMyPageStudyRoomReportInfo(params);
      return data;
    },
    async fetchMyPageStudyRoomReportDetail(boardContentsSeq: number) {
      const { data } = await myPageService.getMyPageStudyRoomReportDetail(boardContentsSeq);
      return data;
    },
    async applyStudyBreak(params: StudyBreakOptions) {
      const { data } = await myPageService.applyStudyBreak(params);
      return data;
    },
    async applyStudyBreakAlarm(params: StudyBreakOptions) {
      const { data } = await myPageService.applyStudyBreakAlarm(params);
      return data;
    },
    async cancelStudyBreak(params: StudyBreakOptions) {
      const { data } = await myPageService.cancelStudyBreak(params);
      return data;
    },
    async fetchMyPagePayments(params: MyPagePaymentSearchOptions, append = false) {
      const { data } = await myPageService.getMyPagePayments(params);

      if (append) {
        this.myPagePayments.items = this.myPagePayments.items.concat(data.data.items);
        this.myPagePayments.paging = data.data.paging;
      } else {
        this.myPagePayments = data.data;
      }

      return data;
    },
    async fetchRefundReasons() {
      const { data } = await myPageService.getPaymentRefundReasons();
      this.refundReasons = data.data.items;
      return data;
    },
    async requestPaymentRefund(params: MyPageRefundRequestOptions) {
      const { data } = await myPageService.requestPaymentRefund(params);
      return data;
    },
    async fetchPaymentRefundLogs(paymentSeq: number) {
      const { data } = await myPageService.getPaymentRefundLogs(paymentSeq);
      this.paymentRefundLogs = data.data.items;
      return data;
    },
    async cancelPaymentRefundRequest(params: MyPageRefundRequestCancelOptions) {
      const { data } = await myPageService.cancelPaymentRefundRequest(params);
      return data;
    },
  },
  getters: {
    getterMainInfo({ mainInfo }): GetterMyPageMainUser | {} {
      return mainInfo
        ? {
            ...mainInfo,
            studyCountLabel: Utility.setCommaPer3Digit(mainInfo.studyCount),
            couponCountLabel: Utility.setCommaPer3Digit(mainInfo.couponCount),
            displayFavoriteCountLabel: Utility.setCommaPer3Digit(mainInfo.displayFavoriteCount),
            pointsSumLabel: Utility.setCommaPer3Digit(mainInfo.pointsSum),
            studyTotalCountLabel: Utility.setCommaPer3Digit(mainInfo.studyTotalCount),
          }
        : {};
    },
    getterProfileInfo({ profile }): GetterMyPageProfile | {} {
      const userAuth = useUserAuthStore();
      return profile
        ? {
            ...profile,
            badgeCountLabel: Utility.setCommaPer3Digit(profile.badgeCount),
            bbsCommentCountLabel: Utility.setCommaPer3Digit(profile.bbsCommentCount),
            bbsContentsCountLabel: Utility.setCommaPer3Digit(profile.bbsContentsCount),
            followerCountLabel: Utility.setCommaPer3Digit(profile.followerCount),
            followingCountLabel: Utility.setCommaPer3Digit(profile.followingCount),
            isMe: userAuth.user && userAuth.user.userId == profile.userId,
          }
        : {};
    },
    getMyContents({ myContents }): GetterBoardContent[] {
      return myContents.map((item) => {
        return Utility.handleCommunityContent(item);
      });
    },
    getMyLikeContents({ myLikeContents }): GetterBoardContent[] {
      return myLikeContents.map((item) => {
        return Utility.handleCommunityContent(item);
      });
    },
    getMyCommentContents({ myCommentContents }): GetterBoardContent[] {
      // RAVQ-147 게시글에서 중복된 댓글 존재시 1개만 노출되도록 처리..
      const filteredComments = [];
      const groupBy = _.groupBy(myCommentContents, 'boardContentsSeq');
      Object.keys(groupBy).forEach((k) => {
        const target = groupBy[k][0];
        filteredComments.push(target);
      });

      const orderBy = _.orderBy(filteredComments, 'commentSeq', 'desc');
      return orderBy.map((item) => {
        return Utility.handleCommunityContent(item);
      });
    },
    getFollowers({ followers }): GetterFollowUser[] {
      return followers.map((item) => {
        return { ...item };
      });
    },
    getFollowings({ followings }): GetterFollowUser[] {
      return followings.map((item) => {
        return { ...item };
      });
    },
    getMyInviteInfo({ myInvite }): GetterMyPageInviteInfo {
      return myInvite
        ? {
            ...myInvite,
            inviteSuccessCountLabel: Utility.setCommaPer3Digit(myInvite.inviteSuccessCount),
            inviteTotalPointLabel: Utility.setCommaPer3Digit(myInvite.inviteTotalPoint),
          }
        : {
            myInviteCode: '',
            inviteCode: '',
            inviteCodeRegYn: 'N',
            inviteSuccessCount: '0',
            inviteTotalPoint: '0',
            inviteUserId: '',
            inviteSuccessCountLabel: '',
            inviteTotalPointLabel: '',
          };
    },
    getMyCoupons({ myCoupons }): GetterMyPageCoupon[] {
      // @ts-ignore
      return myCoupons
        .map((item) => {
          return {
            ...item,
            couponEndDtLabel: item.couponEndDt ? $dateFormat(item.couponEndDt, 'YYYY.MM.DD HH:mm') : '', // 시분 까지 추가
            couponStartDtLabel: item.couponStartDt ? $dateFormat(item.couponStartDt, 'YYYY.MM.DD HH:mm') : '', // 시분 까지
            discountPriceLabel: Utility.setCommaPer3Digit(item.discountPrice), // 가격에대한 콤마
            discountTypeLabel: item.discountType === 'W' ? '원' : '%',
          };
        })
        .sort((a, b) => {
          if (a.useText === '사용가능' && b.useText !== '사용가능') {
            return -1;
          }
          if (a.useText !== '사용가능' && b.useText === '사용가능') {
            return 1;
          }
          return 0;
        });
    },
    getMyBadges({ myBadges }): MyPageBadge[] {
      return myBadges;
    },
    getMyPageInfo({ myInfo }): GetterMyPageInfo {
      return myInfo ? { ...myInfo } : ({} as GetterMyPageInfo);
    },
    getMyPageInvitedUsers({ invitedUsers }) {
      return invitedUsers.map((item) => ({ ...item }));
    },
    getMyPageMainInfo({ myPageMainInfo }) {
      return myPageMainInfo ? { ...myPageMainInfo } : ({} as MyPageMainInfo);
    },
    getMyPageStudyRoomLectures({ myPageStudyRoomLectures }): MyPageStudyRoomLecture[] {
      const chapters = myPageStudyRoomLectures.filter((item) => item.lectureTypeCd === 'CHAPTER');
      const result = chapters.map((chapter) => {
        const children = myPageStudyRoomLectures.filter(
          (item) => item.lectureTypeCd !== 'CHAPTER' && item.productLecPUuid === chapter.productLecUuid,
        );
        return { ...chapter, children };
      });
      return result;
    },
    getMyPageStudyRoomLectureClassInfo({ myPageStudyRoomLectureClassInfo }) {
      return myPageStudyRoomLectureClassInfo ? { ...myPageStudyRoomLectureClassInfo } : ({} as MyPageStudyRoomClass);
    },
    getMyPageWriteReviews({ myPageWriteReviews }): GetterMyPageReview[] {
      return myPageWriteReviews.items.map((item) => {
        return {
          ...item,
          type: 'write',
          reviewCountLabel: item.reviewCount > 999 ? '+999' : `${item.reviewCount}`,
          studyStartDateLabel: $dateFormat(item.studyStartDate),
          studyEndDateLabel: $dateFormat(item.studyEndDate),
          creatorNamesLabel: item.creatorNames.join(', '),
        };
      });
    },
    getMyPageModifyReviews({ myPageModifyReviews }): GetterMyPageReview[] {
      return myPageModifyReviews.items.map((item) => {
        return {
          ...item,
          type: 'modify',
          reviewCountLabel: item.reviewCount > 999 ? '+999' : `${item.reviewCount}`,
          studyStartDateLabel: $dateFormat(item.studyStartDate),
          studyEndDateLabel: $dateFormat(item.studyEndDate),
          creatorNamesLabel: item.creatorNames.join(', '),
        };
      });
    },
    getMyPagePointInfo({ myPagePointInfo }) {
      return myPagePointInfo ? { ...myPagePointInfo } : ({} as MyPagePointInfo);
    },
    getMyPagePointLogs({ myPagePointLogs }): MyPagePointLog[] {
      return myPagePointLogs.items.map((item) => {
        return { ...item };
      });
    },
  },
});
